// src/components/Footer.js
import React from 'react';


const Footer = () => (
  <div style={{ textAlign: 'center', marginTop: '20px' }}>
            <div className="login-form-policy">
            By continuing, you agree to our Terms of Use, Privacy Notice, Cookie Notice, and Interest-Based Ads Notice.        </div>

      <footer className="login-footer">
        <p>© 2024, Uonify.com, Inc. or its affiliates</p>
      </footer>


  </div>
);

export default Footer;
