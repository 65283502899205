import React, { useState, useEffect } from "react";
import "./ReadData.css"; // Asegúrate de tener un archivo CSS para los estilos

const ReadData = ({ user }) => {
  const [records, setRecords] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredRecords, setFilteredRecords] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(10);

  // Obtener los registros desde el endpoint
  useEffect(() => {
    const fetchRecords = async () => {
      try {
        const response = await fetch("https://6elcbn2wjl.execute-api.us-east-1.amazonaws.com/read");
        if (!response.ok) {
          throw new Error(`Error fetching records: ${response.statusText}`);
        }
        const data = await response.json();
        setRecords(data);
        setFilteredRecords(data);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchRecords();
  }, []);

  // Filtrar los registros según el término de búsqueda
  useEffect(() => {
    const filtered = records.filter((record) =>
      `${record.nombre} ${record.apellido}`.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredRecords(filtered);
    setCurrentPage(1); // Reiniciar a la primera página al filtrar
  }, [searchTerm, records]);

  // Función para extraer el nombre del archivo
  const getFileName = (filePath) => {
    const parts = filePath.split('/');
    return parts[parts.length - 1];
  };

  // Paginación
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = filteredRecords.slice(indexOfFirstRecord, indexOfLastRecord);
  const totalPages = Math.ceil(filteredRecords.length / recordsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Manejar clic en el botón "Enviar Correo" para cada usuario
  const handleSendEmail = (userEmail) => {
    console.log(`Enviar correo a ${userEmail} (funcionalidad no implementada)`);
  };

  return (
    <div className="contenedor-read">
      <h1>Dear {user?.username}</h1>

      {/* Barra de búsqueda */}
      <input
        type="text"
        placeholder="Search by name or lastname"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="search-bar"
      />

      {/* Contenedor para el desplazamiento horizontal de la tabla */}
      <div className="table-container">
        {/* Tabla de registros */}
        <table className="document-table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Lastname</th>
              <th>Email</th>
              <th className="download-links-col">Download Links</th>
              <th className="email-action-col">Actions</th> {/* Columna para acciones */}
            </tr>
          </thead>
          <tbody>
            {currentRecords.map((record, index) => (
              <tr key={index}>
                <td>{record.nombre}</td>
                <td>{record.apellido}</td>
                <td>{record.Email}</td>
                <td className="download-links-col">
                  {record.file_urls?.map((url, fileIndex) => (
                    <div key={fileIndex}>
                      <a href={url} download className="download-link">
                        ➤ {getFileName(record.files[fileIndex])}
                      </a>
                    </div>
                  ))}
                </td>
                <td className="email-action-col">
                  <button 
                    className="btn-submit" 
                    onClick={() => handleSendEmail(record.Email)}
                  >
                    Enviar Correo
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Paginación */}
      <div className="pagination">
        {Array.from({ length: totalPages }, (_, index) => (
          <button
            key={index}
            onClick={() => handlePageChange(index + 1)}
            className={currentPage === index + 1 ? 'active' : ''}
          >
            {index + 1}
          </button>
        ))}
      </div>
    </div>
  );
};

export default ReadData;
