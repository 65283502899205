import React, { useState, useRef, useEffect } from "react";
import "./UploadDocuments.css";

const UploadDocuments = ({ user }) => {
  const [files, setFiles] = useState([]);
  const [metadata, setMetadata] = useState({
    nombre: "",
    apellido: "",
    Dia: "",
    Mes: "",
    Año: "",
  });
  const [isDragging, setIsDragging] = useState(false);
  const fileInputRef = useRef(null);

  // Obtener la fecha actual y separar en día, mes y año
  useEffect(() => {
    const currentDate = new Date();
    const currentDay = currentDate.getDate();
    const currentMonth = currentDate.getMonth() + 1;
    const currentYear = currentDate.getFullYear();

    setMetadata((prevMetadata) => ({
      ...prevMetadata,
      Dia: currentDay.toString(),
      Mes: currentMonth.toString(),
      Año: currentYear.toString(),
    }));
  }, []);
  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const droppedFiles = Array.from(e.dataTransfer.files);
    setFiles((prevFiles) => [...prevFiles, ...droppedFiles]);
    setIsDragging(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };

  const handleDropzoneClick = () => {
    fileInputRef.current.click();
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setMetadata((prevMetadata) => ({
      ...prevMetadata,
      [name]: value,
    }));
  };

  const handleRemoveFile = (fileName) => {
    setFiles((prevFiles) => prevFiles.filter((file) => file.name !== fileName));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    console.log("CloudFront Dev URL:", process.env.REACT_APP_DEV_CLOUDFRONT_URL);
    console.log("CloudFront Prod URL:", process.env.REACT_APP_PROD_CLOUDFRONT_URL);
    console.log("S3 Bucket Dev URL:", process.env.REACT_APP_DEV_S3_BUCKET_URL);
  
    if (files.length === 0) {
      alert("Please select at least one file");
      return;
    }

    try {
      const filesBase64 = await Promise.all(
        files.map(async (file) => {
          const fileContentBase64 = await fileToBase64(file);
          return {
            file_name: file.name,
            file_content: fileContentBase64,
          };
        })
      );

      const response = await fetch(
        "https://twqu3vywg0.execute-api.us-east-1.amazonaws.com/save",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            metadata: metadata,
            files: filesBase64,
          }),
        }
      );

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(
          `Error uploading files: ${response.status} ${errorText}`
        );
      }

      alert("Files uploaded successfully.");
      setFiles([]);
    } catch (error) {
      console.error("Error:", error);
      alert(`There was a problem uploading the files: ${error.message}`);
    }
  };

  const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result.split(",")[1]);
      reader.onerror = (error) => reject(error);
    });
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <div className="contenedor-document">
      <h1>Dear {user?.username ? capitalizeFirstLetter(user.username) : ""}</h1>
      <p>Welcome to Uonify</p>

      <form onSubmit={handleSubmit} className="upload-form">
        <div
          className={`dropzone ${isDragging ? "dragging" : ""}`}
          onClick={handleDropzoneClick}
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          tabIndex={0}
          onKeyPress={(e) => {
            if (e.key === "Enter" || e.key === " ") {
              handleDropzoneClick();
            }
          }}
        >
          <input
            type="file"
            ref={fileInputRef}
            onChange={handleFileChange}
            className="form-control"
            multiple
            style={{ display: "none" }}
          />
          <span>Drag files here or click to select</span>
        </div>

        {files.length > 0 && (
          <div className="file-list">
            <h3>Selected Files:</h3>
            <ul>
              {files.map((file) => (
                <li key={file.name}>
                  {file.name}
                  <button
                    type="button"
                    onClick={() => handleRemoveFile(file.name)}
                  >
                    Remove
                  </button>
                </li>
              ))}
            </ul>
          </div>
        )}

        <div className="form-group">
          <label htmlFor="name">Name:</label>
          <input
            type="text"
            id="name"
            name="nombre"
            value={metadata.nombre}
            onChange={handleInputChange}
            className="form-control"
          />
        </div>

        <div className="form-group">
          <label htmlFor="lastname">Last name:</label>
          <input
            type="text"
            id="lastname"
            name="apellido"
            value={metadata.apellido}
            onChange={handleInputChange}
            className="form-control"
          />
        </div>

        <button type="submit" className="btn-submit">
          Upload Documents
        </button>
      </form>
    </div>
  );
};

export default UploadDocuments;
